<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
<!--        <el-form-item prop="parkingLotId">-->
<!--          <SuggestParking v-model="query.parkingLotId" style="width: 300px"/>-->
<!--        </el-form-item>-->
<!--        <el-form-item prop="parkingLotType">-->
<!--          <el-select-->
<!--              v-model="query.parkingLotType"-->
<!--              clearable-->
<!--              placeholder="请选择车场类型"-->
<!--              style="width: 300px"-->
<!--          >-->
<!--            <el-option label="路内" value="0"></el-option>-->
<!--            <el-option label="路外" value="1"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item prop="type">
          <el-select
              v-model="query.type"
              clearable
              placeholder="请选择名单类型"
              class="width300"
          >
            <el-option label="黑名单" value="0"></el-option>
            <el-option label="白名单" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status">
          <el-select
              v-model="query.status"
              clearable
              placeholder="请选择生效状态"
              class="width300"
          >
            <el-option label="正常" value="0"></el-option>
            <el-option label="待生效" value="1"></el-option>
            <el-option label="已过期" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="vehicleId">
          <NumplateSuggest v-model="query.vehicleId"
                           class="width300"/>
        </el-form-item>
        <el-form-item prop="timeRange">
          生效时间：
          <!--            <el-select v-model="query.timeRange" placeholder="时间范围">-->
          <!--              <el-option label="生效时间" value="1"></el-option>-->
          <!--              <el-option label="到期时间" value="2"></el-option>-->
          <!--              &lt;!&ndash; <el-option value="3" label="生效单日"></el-option> &ndash;&gt;-->
          <!--            </el-select>-->
          <el-date-picker
              v-model="query.timeValue"
              end-placeholder="结束日期"
              prefix-icon="none"
              range-separator="~"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="establishTimeValue">
          创建时间：
          <el-date-picker
              v-model="query.establishTimeValue"
              end-placeholder="结束日期"
              prefix-icon="none"
              range-separator="~"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="goAdd">新增</el-button>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column align="center" label="车牌号" width="120">
          <template slot-scope="scope">
            <Numplate :type="scope.row.plateColor">{{
                scope.row.plateNumber
              }}
            </Numplate>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="名单类型"
            prop="nameListType"
            width="140"
        >
          <template slot-scope="scope">
            {{ scope.row.isWhite === '0' ? "黑名单" : "白名单" }}
          </template>
        </el-table-column>
        <el-table-column label="适用车场" prop="parkingPlotName" width="240">
        </el-table-column>
        <el-table-column label="生效时间" prop="beginDate" width="180">
          <template slot-scope="scope">
            <span>{{ dateFormat(new Date(scope.row.beginDate)) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="到期时间" prop="endDate" width="180">
          <template slot-scope="scope">
            <span>{{ dateFormat(new Date(scope.row.endDate)) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="180">
          <template slot-scope="scope">
            <span>{{ dateFormat(new Date(scope.row.createTime)) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" width="160">
          <template slot-scope="scope">
            <!--            <span v-if="scope.row.isDelete === 1">已删除</span>-->
            <span>{{ status[scope.row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注信息" prop="remarks">
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                class="common-text"
                size="small"
                type="text"
                @click="goDelete(scope.row.id)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加黑白名单弹窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="beforeAddDialogClose"
        :visible.sync="addConfigDialogVisible"
        class="common-dialog"
        title="添加黑/白名单"
        width="1000px"
    >
      <el-form
          ref="form"
          v-loading="isLoading"
          :model="form"
          :rules="rules"
          class="label-weight"
      >
        <easy-card title="基础信息">
          <el-row>
            <el-col :span="12">
              <el-form-item
                  label="名单类型"
                  label-width="120px"
                  prop="isWhite"
                  required
              >
                <el-select
                    v-model="form.isWhite"
                    class="common-input width260"
                    placeholder="请选择黑/白名单"
                >
                  <el-option label="黑名单" value="0"></el-option>
                  <el-option label="白名单" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                  label="适用车场"
                  label-width="120px"
                  prop="parkingLotId"
                  required
              >
                <SuggestParking
                    v-model="form.parkingLotId"
                    :multiple="isMultiple"
                    class="width260"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item
                  label="车牌颜色"
                  label-width="120px"
                  prop="carType"
                  required
              >
                <el-select
                    v-model="form.carType"
                    placeholder="请选择车牌颜色"
                    class="width260"
                >
                  <el-option label="黄牌" value="01"></el-option>
                  <el-option label="蓝牌" value="02"></el-option>
                  <el-option label="绿牌" value="03"></el-option>
                  <el-option label="黄绿" value="04"></el-option>
                  <el-option label="黑牌" value="06"></el-option>
                  <el-option label="白牌" value="23"></el-option>
                  <el-option label="其他" value="99"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                  label="车牌号码"
                  label-width="120px"
                  prop="carNumber"
                  required
              >
                <el-input
                    v-model="form.carNumber"
                    placeholder="请输入车牌号码"
                    class="width260"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item
                  label="开始时间"
                  label-width="120px"
                  prop="startTime"
                  required
              >
                <el-date-picker
                    v-model="form.startTime"
                    placeholder="请选择生效时间"
                    class="width260"
                    type="date"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                  label="结束时间"
                  label-width="120px"
                  prop="endTime"
                  required
              >
                <el-date-picker
                    v-model="form.endTime"
                    placeholder="请选择到期时间"
                    class="width260"
                    type="date"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
<!--                <el-button-->
<!--                    size="small"-->
<!--                    style="margin-left: 10px"-->
<!--                    type="primary"-->
<!--                    @click="setEndTime"-->
<!--                ><span>永久</span>-->
<!--                </el-button>-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
            <el-form-item label="备注信息" label-width="120px" prop="remarks">
              <el-input
                  v-model="form.remarks"
                  :rows="3"
                  placeholder="请输入备注信息"
                  style="width: 260px"
                  type="textarea"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        </easy-card>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="saveBlackWhiteList">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 批量上传 -->
    <el-dialog
        v-loading="isLoading"
        :append-to-body="true"
        :visible.sync="uploadDialogFormVisible"
        class="common-dialog"
        title="批量上传"
        width="1200px"
        @close="onBatchUploadClosed"
    >
      <div style="margin-bottom: 16px; display: flex; flex-direction: row">
        <el-link
            :underline="false"
            download="黑白名单导入模板.xlsx"
            href="/file/blackwhitelist.xlsx"
        >
          <el-button style="margin-right: 10px" type="primary"
          >下载模板
          </el-button
          >
        </el-link>
        <el-upload
            :before-upload="handleBeforeUpload"
            :http-request="doUpload"
            :show-file-list="false"
            accept=".xlsx"
            action=""
        >
          <el-button icon="el-icon-upload" type="success">上传文件</el-button>
        </el-upload>
        <div class="total-amount">
          上传总数量：<span style="color: #0768FD">{{
            batchModel.upLoadList.length
          }}</span>
        </div>
      </div>
      <div>
        <el-table
            v-loading="isUpLoadLoading"
            :data="batchModel.upLoadList"
            border
            class="common-table"
            height="400px"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="名单类型" prop="nameListType" width="80">
            <template slot-scope="scope">
              {{ scope.row.isWhite === "0" ? "黑名单" : "白名单" }}
            </template>
          </el-table-column>
          <el-table-column label="车牌号码" prop="vehicleNumber" width="120">
            <template slot-scope="scope">
              <Numplate :type="scope.row.plateType">{{
                  scope.row.plateNum
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column label="车牌颜色" prop="vehicleColor" width="80">
            <template slot-scope="scope">
              {{ vehicleColor[scope.row.plateType] }}
            </template>
          </el-table-column>
          <el-table-column label="车场名称" prop="parkingLotName" width="250">
          </el-table-column>
          <el-table-column label="开始时间" prop="startDate" width="120">
          </el-table-column>
          <el-table-column label="结束时间" prop="endDate" width="120">
          </el-table-column>
          <el-table-column label="所属单位" prop="unit"></el-table-column>
          <el-table-column label="备注信息" prop="remark"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="success" @click="save">保存</el-button>
        <!-- <el-button type="primary" @click="clean">清理</el-button> -->
        <el-button @click="cancelUpLoad">取消</el-button>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="exportForm" :model="exportForm" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="exportForm.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Numplate from "@/components/Numplate";
import SuggestParking from "@/components/SuggestParking";
import NumplateSuggest from "@/components/NumplateSuggest";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import {checkToken, doExport} from "@/api/common";
import {addParking, affirm, deleteParking, doUploadAnalysisExcel, selectVehicle,} from "@/api/blackwhite";
import {MessageBox} from "element-ui";

export default {
  name: "blackwhitelist",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      isLoading: false,
      query: {
        parkingLotId: "", //停车场Id
        nameListType: "", //名单类型   0黑名单   1白名单
        status: "", //生效状态
        vehicleId: "", //车牌号
        mobile: "", //手机号
        timeRange: "1", //时间范围
        timeValue: [],
        startTime: "", //开始时间
        endTime: "", //结束时间
        establishTimeValue: [],
        establishStartTime: "", //创建开始时间
        establishEndTime: "", //创建结束时间
        motionType: "0", //0-运营,1-机关事业单位,2-联网
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      exportForm: {
        exportSize: 1,
      },
      dataList: [],
      status: {
        0: "正常",
        1: "待生效",
        2: "已过期",
      },
      addConfigDialogVisible: false, //添加黑白名单弹窗
      isMultiple: true, //停车场多选
      uploadDialogFormVisible: false, //批量添加弹窗
      form: {
        //添加黑白名单数据
        isWhite: "",
        parkingLotId: [],
        carType: "02",
        carNumber: "",
        startTime: "",
        endTime: "",
        // company: "",
        remarks: "",
      },
      rules: {
        isWhite: [{required: true, message: "请选择黑/白名单"}],
        parkingLotId: [{required: true, message: "请选择适用车场"}],
        carType: [{required: true, message: "请选择车牌颜色"}],
        // carNumber: [
        //   {
        //     required: true,
        //     validator: (rule, value, callback) => {
        //       if (value === "") {
        //         callback(new Error("车牌号不能为空"));
        //       } else if (
        //           !/^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[a-zA-Z](([a-zA-Z]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[a-zA-Z]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1})$/.test(
        //               value
        //           )
        //       ) {
        //         callback(new Error("车牌号码格式不正确"));
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
        startTime: [{required: true, message: "请选择生效时间"}],
        endTime: [{required: true, message: "请选择到期时间"}],
      },
      //批量上传
      isUpLoadLoading: false,
      batchModel: {
        upLoadList: [], //列表展示
      },
      vehicleColor: {
        "01": "黄牌",
        "02": "蓝牌",
        "06": "黑牌",
        "03": "绿牌",
        "04": "黄绿牌",
        23: "白牌",
      },
    };
  },
  components: {
    SuggestParking,
    NumplateSuggest,
    Numplate,
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.query.timeValue = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageSize = 10;
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
      checkToken()
          .then(async res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              this.isLoading = true;
              const params = this.paramFormat(this.query);
              if (this.query.timeValue.length > 0) {
                params["beginDate"] = this.query.timeValue[0] + " 00:00:00";
                params["endDate"] = this.query.timeValue[1] + " 23:59:59";
              }
              if (this.query.establishTimeValue.length > 0) {
                params["createStartTime"] =
                    this.query.establishTimeValue[0] + " 00:00:00";
                params["createEndTime"] =
                    this.query.establishTimeValue[1] + " 23:59:59";
              }
              const res = await selectVehicle(params);
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                this.dataList = returnObject.records;
                this.pagination.totalSize = returnObject.total;
                this.pagination.totalPages = returnObject.pages;
                this.exportForm.exportSize = returnObject.total;
              }
            }
          })
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.timeValue.length > 0) {
        params["startTime"] = this.query.timeValue[0] + " 00:00:00";
        params["endTime"] = this.query.timeValue[1] + " 23:59:59";
      }
      if (this.query.establishTimeValue.length > 0) {
        params["establishStartTime"] =
            this.query.establishTimeValue[0] + " 00:00:00";
        params["establishEndTime"] =
            this.query.establishTimeValue[1] + " 23:59:59";
      }
      params["isExport"] = "1";
      params["exportSize"] = this.exportForm.exportSize;
      doExport(
          "/blackWhiteListController/selectVehicle",
          params,
          "黑白名单.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //删除
    goDelete(e) {
      const objectID = e;
      this.$confirm("此操作将删除黑名单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.isLoading = true;
        const res = await deleteParking(objectID);
        setTimeout(() => {
          this.isLoading = false;
          if (res && res.code === 30 && res.result) {
            this.$message.success("删除成功！");
            this.doSearch();
          }
        }, 2000);
        // deleteParking(objectID).then((res) => {
        //   if (res && res.code === 30 && res.result) {
        //     this.$message.success("删除成功！");
        //     this.doSearch();
        //   }
        // });
      });
    },
    //添加黑白名单
    goAdd() {
      this.addConfigDialogVisible = true;
    },
    //关闭弹窗回调，重置表单
    beforeAddDialogClose(done) {
      this.$refs.form.resetFields();
      done();
    },
    //取消添加黑白名单
    cancel() {
      this.addConfigDialogVisible = false;
      this.$refs.form.resetFields();
    },
    //结束时间设置永久
    setEndTime() {
      this.form.endTime = "2999-01-01";
    },
    //确定添加黑白名单
    saveBlackWhiteList() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.isLoading = true;
          // const data = {
          //   nameListType: this.form.nameListType,
          //   parkingLotIds: this.form.parkingLotIds,
          //   vehicleColor: this.form.vehicleColor,
          //   vehicleNumber: this.form.vehicleNumber,
          //   startTime: this.form.startTime,
          //   endTime: this.form.endTime,
          //   company: this.form.company,
          //   remarks: this.form.remarks,
          // };
          // debugger
          // const res = await addParking(this.form);
          // setTimeout(() => {
          //   this.isLoading = false;
          //   if (res && res.code === 30 && res.result) {
          //     this.$message.success("添加成功！");
          //     this.addConfigDialogVisible = false; //关闭弹窗
          //     this.$refs.form.resetFields();
          //     this.doSearch();
          //   }
          // }, 2000);
          addParking(this.form).then((res) => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("添加成功！");
              this.addConfigDialogVisible = false; //关闭弹窗
              this.$refs.form.resetFields();
              this.doSearch();
            }
          });
        } else {
          return false;
        }
      });
    },
    //批量上传
    batchAdd() {
      this.uploadDialogFormVisible = true;
    },
    //上传
    handleBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isExcel = testmsg === "xlsx";
      if (!isExcel) {
        this.$message({
          message: "只能上传.xlsx文件",
          type: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    async doUpload(file) {
      // console.log(file);
      this.isUpLoadLoading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await doUploadAnalysisExcel(formData);
      this.isUpLoadLoading = false;
      if (res && res.code === 30 && res.result) {
        this.batchModel.upLoadList = res.returnObject;
      }
    },
    //取消上传
    cancelUpLoad() {
      this.uploadDialogFormVisible = false;
    },
    //关闭弹窗清空表单
    onBatchUploadClosed() {
      this.batchModel.upLoadList = [];
    },
    //保存模板数据
    save() {
      const params = JSON.stringify({
        xlsxVO: this.batchModel.upLoadList,
      });
      this.isLoading = true;
      affirm(params).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message.success("保存成功！");
          this.uploadDialogFormVisible = false;
          this.batchModel.upLoadList = [];
          this.doSearch();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.total-amount {
  font-size: 18px;
  margin-top: 12px;
  margin-left: 50px;
  color: #000000;
}
</style>
